@import '@/styles/theme/variables.scss';

.TwelveColumnSubGridFix {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

@media #{$usb-max-lg} {
  .TwelveColumnSubGridFix {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
}

@media #{$usb-max-lg} {
  .TwelveColumnSubGridFix {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.fullWidth {
  width: 100%;
}

.headH2 {
  @include headH2;
}

.paragraph {
  @include paragraph;

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-40px;
  }

  p {
    @include paragraphP;
  }
}

.headH3,
.headingH2 {
  width: $token-spacing-281px;
  height: $token-spacing-21px;
  left: $token-spacing-257-5px;
  top: $token-spacing-1878-6px;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-sm;
  line-height: $token-line-height-loose;
  /* identical to box height, or 150% */
  letter-spacing: $token-spacing-xs;
  text-transform: uppercase;
  color: $token-color-grey-90;
}

.blockWrapperContainer {
  padding-bottom: $token-spacing-4;
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
  .gridWrapper {
    padding-top: $token-spacing-0 !important;
    padding-bottom: $token-spacing-0 !important;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}

@media (min-width: $token-breakpoint-x-large) { 
  .gridWrapper {
    max-width:$token-spacing-1650px !important;
  }
  
  .fullWidth, .TwelveColumnSubGridFix {
    max-width:$token-spacing-1120px !important;
  }
}
