@import '@/styles/theme/variables.scss';

.sbImageWrapper {
  float: left;
  display: flow-root;
  margin-bottom: $token-spacing-2;
  width: $token-spacing-10;
}

.sbContentWrapper {
  float: left;
  display: flow-root;
}

.eafContentWrapper {
  float: left;
  display: flow-root;
}

.eafWrapper {
  width: 33.3%;
  display: inline-block;
  padding: $token-spacing-0 $token-spacing-10-4px $token-spacing-20;
}

.eapiImageWrapper {
  padding-bottom: $token-spacing-2;
}

.buttonGetStarted {
  margin: $token-spacing-0 !important;
  padding: $token-spacing-2 $token-spacing-3 !important;
}

.gsWrapper {
  width: 100%;
  float: left;
  display: flow-root;
}

.sbImageWrapper img {
  width: 100% !important;
  height:$token-spacing-10 !important;
}

.headH2Eaf {
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-line-height-30px;
  color: $token-text-color-primary;
  padding-bottom: $token-spacing-2;
}

.headH3 {
  margin-bottom: $token-spacing-3;
  font-size: $token-font-size-xl;
  font-weight: $token-font-weight-med;
  line-height: $token-line-height-30px;
}

.paragraph {
  @include paragraph;
  font-size: $token-font-size-base;
  font-weight: $token-font-weight-reg;
  line-height: $token-line-height-loose;
  margin-bottom: $token-spacing-2;

  ul {
    @include listUl;
    padding-inline-start:$token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .headH3 {
    margin-bottom: $token-spacing-6;
  }

  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }

  .sbContentWrapper {
    padding-left: $token-spacing-0;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 360px) {
  .gsWrapper {
    width: 100%;
  }
  .gsWrapper,
  .sbWrapper {
    padding: $token-spacing-2 $token-spacing-0;
  }

  .gsImageWrapper {
    display: inline-flex;
  }

  .eafWrapper {
    width: 100%;
    padding: $token-spacing-0 $token-spacing-10-4px $token-spacing-8;
  }

  .eafParagraph {
    width: $token-spacing-256px;
  }

  .ctaLink {
    position: absolute;
    padding: $token-spacing-2 $token-spacing-11 !important;
  }

  .buttonGetStarted {
    left: $token-spacing-2;
    bottom: $token-spacing-3 !important;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 540px) {
  .gsWrapper {
    width: 100%;
  }
  .gsWrapper,
  .sbWrapper {
    padding: $token-spacing-2 $token-spacing-0;
  }

  .buttonGetStarted {
    left: $token-spacing-2;
    bottom: $token-spacing-3 !important;
  }

  .eafWrapper {
    width: 100%;
    padding: $token-spacing-0 $token-spacing-10-4px $token-spacing-14;
  }

  .gsImageWrapper {
    display: inline-flex;
  }

  .ctaLink {
    position: absolute !important;
    padding: $token-spacing-2 $token-spacing-11 !important;
  }
}

@media only screen and (min-device-width: 541px) and (max-device-width: $usb-screen-767) {
  .gsWrapper {
    width: 100%;
  }
  .gsWrapper,
  .sbWrapper {
    padding: $token-spacing-1 $token-spacing-0;
  }

  .gsImageWrapper {
    display: inline-flex;
  }

  .eafWrapper {
    padding: $token-spacing-0 $token-spacing-10-4px $token-spacing-12;
    width: 100%;
  }

  .eafParagraph {
    width: $token-spacing-480px;
  }

  .ctaLink {
    position: absolute;
    padding: $token-spacing-3 $token-spacing-11 !important;
    bottom: $token-spacing-38px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 915px) {
  .sbWrapper {
    padding: $token-spacing-2 $token-spacing-0;
  }

  .gsWrapper {
    width: 100% !important;
  }

  .eafWrapper {
    padding: $token-spacing-0 $token-spacing-10-4px $token-spacing-12;
    width: 50% !important;
  }

  .eafParagraph {
    width: $token-spacing-304px;
  }

  .buttonGetStarted {
    margin: $token-spacing-0 !important;
    padding: $token-spacing-2 $token-spacing-3 !important;
  }
}

@media only screen and (min-device-width: 720px) and (max-device-width: 740px) {
  .ctaLink {
    position: absolute;
    padding: $token-spacing-2 $token-spacing-0 !important;
    bottom: $token-spacing-0 !important
  }
}