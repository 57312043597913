@import '@/styles/theme/variables.scss';

.overviewImage {
  height: 100%;
  width: 100%;
}

.overviewImage span {
  height: 100% !important;
}

.overviewImageNoTitle {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.headH3 {
  margin-bottom: $token-spacing-4;
  font-size: $token-font-size-xl;
  font-weight: $token-font-weight-med;
  line-height: $token-line-height-30px;
}

.overviewListWithoutTitle {
  div {
    padding-bottom: 0px !important;
    width: 100%;
  }
}

.overviewImageNoTitle span {
  height: 100% !important;
}

.overviewImageDesktop {
  min-height: 100%;
  object-fit: cover;
}

.overviewImageTablet {
  max-height: unset !important;
  height: unset !important;
  top: $token-spacing-112px !important;
}

.solutionOverviewImageTablet {
  max-height: unset !important;
  height: unset !important;
  top: 7rem !important;
  margin-top: 6rem;
}

.solutionsAreaVideo {
  width: 100% !important;
  height: 100% !important;

  iframe {
    height: 100%;
    width: 95.1%;
  }
}

.overviewImageMobile {
  height: unset !important;
  max-height: unset !important;
  max-width: 100%;
}


.paragraph {
  font-size: $token-font-size-base;
  font-weight: $token-font-weight-reg;
  line-height: $token-line-height-loose;
  padding-bottom: $token-spacing-0 !important;

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

.sectionRow {
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-10;
}

.sectionRowNoPaddingBottom {
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-0;
}

.headH2 {
  @include headingFont;
  font-weight: $token-font-weight-med;
  line-height: $token-spacing-10;
  color: $headH2-font-color;
  margin-bottom: $token-spacing-4;
}

.solutionAreaInfo {
  padding-top: $token-spacing-18 !important;
  padding-bottom: $token-spacing-20 !important;

  h2 {
    /* Display Small */
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: $token-font-weight-bold;
    font-size: $token-font-size-3xl;
    /* identical to box height, or 125% */
    color: $token-text-color-brand-primary;
    padding: $token-spacing-10px $token-spacing-0 !important;
  }

  h3 {
    /* Header Medium */
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: $token-font-weight-med;
    font-size: $token-spacing-6;
    /* identical to box height, or 125% */

    color: $token-text-color-primary;
    padding: $token-spacing-0 $token-spacing-0 $token-spacing-10px
      $token-spacing-0 !important;
  }

  p {
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: $token-font-weight-reg;
    font-size: $token-font-size-root;
    /* or 150% */

    /* U.S. Bank/Grays/Gray 90 */
    color: $token-text-color-primary;
  }

  a {
    height: $token-spacing-5;
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: $token-font-weight-med;
    font-size: $token-font-size-base;
    line-height: $token-line-height-tight;
    /* identical to box height, or 125% */

    /* U.S. Bank/Foundation/Interaction Blue */
    color: $token-text-color-interaction;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0.5% $token-spacing-0 !important;
  }
}

.overviewList {
  flex-direction: column !important;
  flex-wrap: nowrap;
  height: 100%;
  padding: $token-spacing-0 0.5% !important;
  margin: $token-spacing-0;

  div {
    width: 50%;
    display: inline-block;
  }
    
  p {
    padding: 1% $token-spacing-0 !important;
  }

  ul {
    list-style: none;
    padding-inline-start: $token-spacing-0 !important;
    width: 50%;
    padding-bottom: 4%;
  }
}

.solutionButton {
  /* .Button/Label - medium */

  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-root;
  line-height: $token-line-height-loose;
  /* identical to box height, or 150% */

  /* U.S. Bank/Foundation/Interaction Blue */

  color: $token-text-color-interaction;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.buttonSao {
  margin: $token-spacing-0 $token-spacing-0 $token-spacing-4 $token-spacing-0 !important;
  white-space: normal;
  word-wrap: break-word;
  min-height: $token-spacing-50px !important;
  text-align: left;
}

.listItem {
  padding: $token-spacing-0 2% 3.125rem $token-spacing-0 !important;
}

.solutionAreaInfo h2,
.solutionAreaInfo p,
.solutionAreaInfo img {
  padding: 2% 2.5% !important;
}

.linkLayout::after {
  content: '';
  border: solid $token-background-brand-interaction;
  border-width: $token-spacing-0 $token-border-width-large
    $token-border-width-large $token-spacing-0;
  display: inline-block;
  padding: $token-spacing-4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(315deg);
  margin-left: $token-spacing-2;
  position: relative;
  top: (-$token-spacing-21px);
}

.solutionAreaLink {
  margin-bottom: $token-spacing-4;
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }

  @include linkFocused;
}

@media only screen and (min-device-width: $usb-screen-820) and (max-device-width: 1023px) {
  .solutionAreaInfo img {
    width: $token-spacing-1024px !important;
  }

  .solutionAreaInfo h2,
  .solutionAreaInfo img {
    padding: $token-spacing-0 2.5% !important;
  }

  .overviewList {
    display: block;
    margin: $token-spacing-0;
    padding-top: 3% !important;
  }

  .soaDescription {
    padding: $token-spacing-0 2.5% !important;
  }
}

@media only screen and (min-device-width: 1100px) {
  .solutionAreaInfo h2,
  .solutionAreaInfo p,
  .solutionAreaInfo img {
    padding: $token-spacing-0 $token-spacing-0 2% $token-spacing-0 !important;
  }
}

@media only screen and (min-device-width: $usb-screen-1024) and (max-device-width: $usb-screen-1024) {
  .solutionAreaInfo h2,
  .solutionAreaInfo p,
  .solutionAreaInfo img {
    padding: 2% 2% !important;
  }
}

@media only screen and (min-device-width: 890px) and (max-device-width: $usb-screen-1024) {
  .solutionAreaInfo img {
    width: $token-spacing-1024px !important;
    margin-top: $token-border-radius-7px !important;
  }

  .overviewList {
    display: block;
    margin: $token-spacing-0;

    .listItem p {
      padding-left: $token-spacing-0 !important;
    }
  }

  .soaDescription {
    padding: 1% $token-spacing-0 2% $token-spacing-0 !important;
  }
}

@media only screen and (min-device-width: 760px) and (max-device-width: 889px) {
  .solutionAreaInfo h2,
  .solutionAreaInfo p,
  .solutionAreaInfo img {
    padding: 1% 2.5% !important;
  }

  .overviewList {
    display: block;
    margin: $token-spacing-0;
    padding-top: 3% !important;

    .listItem p {
      padding-left: $token-spacing-0 !important;
    }
  }
}

@media only screen and (min-device-width: 250px) and (max-device-width: 759px) {
  .solutionAreaInfo img {
    width: 759px !important;
    margin-top: $token-border-radius-7px !important;
  }

  .overviewList {
    display: block;
    margin: $token-spacing-0;
    padding-top: 3% !important;

    div {
      width: auto;
    }

    ul {
      list-style: none;
      padding-inline-start: none;
      width: auto;
      padding-bottom: none;
    }

    .solutionButton {
      margin-top: 4% !important;
      width: 98%;
    }

    .listItem p {
      padding-left: $token-spacing-0 !important;
    }
  }

  .soaDescription {
    padding: 5% 3% !important;
  }
}

@media only screen and (max-width: 671px) {
  .solutionOverviewImageMobileImgContainer {
    margin-top: 0px !important;
  }

  .overviewImageNoTitle {
    iframe {
      width: 100%;
      height: 29vh;
    }
  }
}

@media only screen and (max-width: 671px) {
  .solutionOverviewImageMobileImgContainer {
    margin-top: $token-spacing-112px !important;
  }
}

@media only screen and (min-device-width: 672px) {
  .overviewList {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }

  .overviewListWithoutTitle div {
    width: 100%;
  }
}

@media #{$usb-max-md} {
  .overviewListWithoutTitle div {
    width: 100%;
    order: 1;
    padding: $token-spacing-0 2% $token-spacing-18px $token-spacing-0 !important;
  }

  .solutionLink {
    order: 2;
  }

  .solutionOverviewImage img {
    margin-top: -$token-spacing-160px;
  }

  .solutionOverviewImageMobileImgContainer {
    margin-top: $token-spacing-112px !important;
  }
}

@media screen and (min-width: 720px) and (max-width: $usb-screen-1368) {
  .solutionLink {
    order: 2;
    background: $token-color-base-blue;
    padding: $token-spacing-3 $token-spacing-8;
    border: $token-border-width-default solid $token-color-base-blue;
    border-radius: $token-border-radius-4px;
    color: $token-background-primary !important;
    left: (-$token-spacing-22);

    svg {
      display: none;
    }
  }

  a.usb-link.arrow .solutionLink {
    color: $token-background-primary !important;
  }
}

@media screen and (min-width: $token-breakpoint-large) and (max-width: 1343px) {
  .overviewListWithoutTitle {
    width: 70%;
  }
}

// Larger than LG screen size
@media screen and (min-width: $token-breakpoint-large) {
  .overviewImageTablet {
    min-width: $token-spacing-720px !important;
    min-height: 100% !important;
    left: -$token-spacing-112px !important;
  }

  .solutionOverviewImageTablet {
    min-width: 45rem !important;
    min-height: 100% !important;
    left: -7rem !important;
  }
}

@media #{$usb-max-759} {
  .overviewImage {
    width: 100%;

    span {
      width: 100% !important;
      max-height: unset !important;
    }
  }

  .overviewImageNoTitle {
    width: 100%;

    span {
      width: 100% !important;
      max-height: unset !important;
    }
  }

  .solutionsAreaVideo {
    iframe {
      height: 28vh !important;
      width: 96.1% !important;
    }
  }

  .overviewListWithoutTitle div {
    padding-bottom: 0px !important;
  }
}

@media (min-width: $token-breakpoint-x-large) {
  .gridWrapper {
    max-width: $token-spacing-1650px !important;
  }

  .solutionOverviewImage img {
    margin-top: -8rem !important;
  }
}

.headH2Sao {
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-2xl;
  line-height: $token-spacing-9;
  color: $token-color-base-dark-gray;
  padding-bottom: $token-spacing-3;
}

.solutionOverviewImageMobile {
  margin-top: -10rem;
}

@media screen and (min-width: $token-breakpoint-medium) and (max-width: $token-breakpoint-large) {
  .sectionRowNoPaddingBottom .overviewList div {
    width: 100% !important;
  }

  .solutionsAreaVideo {
    iframe {
      height: 31vh !important;
      width: 96.1% !important;
    }
  }

  .overviewImageNoTitle {
    iframe {
      height: 34vh;
    }
  }
}

@media screen and (min-width: 672px) and (max-width: 768px) {
  .solutionOverviewImage img {
    margin-top: $token-spacing-112px;
  }

  .solutionOverviewImageMobile {
    margin-top: 8.5rem;
  }

  .solutionsAreaVideo {
    iframe {
      height: 35vh !important;
      width: 96.1% !important;
    }
  }

  .overviewImageNoTitle {
    iframe {
      height: 37vh !important;
    }
  }
}

@media screen and (min-width: 1056px) and (max-width: 1343px) {
  .solutionOverviewImage img {
    margin-top: -$token-spacing-112px;
  }
}
